<template>
  <v-container fluid id="regular-table">
    <v-form ref="form" v-model="is_valid" lazy-validation>
      <div class="d-flex flex-column flex-md-row" style="gap: 20px">
        <div class="me-6">
          <h3 class="text-h3 ma-4">Order Cancellation</h3>

          <v-flex>
            <div
              class="text-h4 d-flex justify-space-between"
              style="min-width: 500px"
            >
              <span class="text-h4 ma-4">Automatic cancellation</span>
              <v-switch
                color="success"
                v-model="automatic_cancellation"
                :label="
                  automatic_cancellation
                    ? `Editing is active`
                    : `Editing is inactive`
                "
              >
              </v-switch>

              <v-text-field
                class="ma-4"
                type="number"
                step="any"
                min="2"
                max="30"
                label="Cancelled period(days)"
                outlined
                dense
                v-model="cancellation_period"
                :disabled="!automatic_cancellation"
              >
                <v-tooltip v-if="automatic_cancellation" bottom slot="append">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="primary" dark>
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    Orders are automatically canceled after
                    {{ cancellation_period }} days.<br />
                    When you manually change this period ,all your currently
                    active orders will be affected
                  </span>
                </v-tooltip>
              </v-text-field>
            </div>
          </v-flex>

          <v-flex class="mt-n8">
            <div
              class="text-h4 d-flex justify-space-between"
              style="max-width: 285px"
            >
              <span class="text-h4 ma-4">Change manually</span>
              <v-switch
                class="text-h4"
                color="success"
                v-model="manual_cancellation"
                :label="`${manual_cancellation ? 'Active' : 'Inactive'}`"
              >
              </v-switch>
            </div>
          </v-flex>
        </div>
      </div>
    </v-form>
    <div class="ma-4">
      <v-btn
        class="primary"
        @click="save()"
        :loading="isLoading"
        v-if="$admin.can('setting-supplyOrder')"
        >save changes</v-btn
      >
      <v-btn color="indigo" text @click="cancel()">cancel</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      is_valid: false,
      automatic_cancellation: false,
      cancellation_period: 7,
      manual_cancellation: false,
      isLoading: false,
    };
  },
  watch() {},
  methods: {
    async save() {
      console.log(this.automatic_cancellation);
      this.validate();
      if (this.is_valid) {
        try {
          this.isLoading = true;
          await this.$store.dispatch("supplyOrders/procurementSettings", {
            automatic_cancellation: this.automatic_cancellation,
            manual_cancellation: this.manual_cancellation,
            cancellation_period: this.cancellation_period,
          });
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.$router.push("/supply-orders");
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    cancel() {
      this.$router.push("/dashboard");
    },
    async load() {
      await this.$store
        .dispatch("supplyOrders/settinglist")
        .then(async (data) => {
          this.manual_cancellation =
            data.supply_order_settings.manual_cancellation;
          this.cancellation_period =
            data.supply_order_settings.cancellation_period;
          this.automatic_cancellation =
            data.supply_order_settings.automatic_cancellation;
        });
    },

    init() {
      this.load();
    },
  },
  created() {
    this.init();
  },
};
</script>
